html,
body {
  margin: 0;
  padding: 0;
}
body {
  font-family: sans-serif;
  background-image: url(assets/felt.png),
    -webkit-radial-gradient(50% 40%, circle farthest-corner, #527c14, #243a0a);
  background-size: 180px 180px, auto;
  background-attachment: fixed;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  margin: 0;
  padding: 0;
}

#app {
  contain: layout style;
  overflow: visible;
}

a, a:visited {
  color: white;
}

.secret-network-address {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.get-scrt-button {
  display: flex;
  align-items: center;
  background-color: darkgreen;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 18px;
  padding: 10px 20px;
  margin-top: 10px;
  margin-left: 120px;
  text-transform: uppercase;
  transition: background-color 0.3s;
  border-radius: 12px;
}

.get-scrt-button:hover {
  background-color: black;
}

.get-scrt-button .material-icons {
  margin-right: 5px;
}

.roulette-wheel {
  float: right;
  width: 380px;
  height: 380px;
  border-radius: 100%;
  background: url(assets/roulette_1.jpg);
  background-size: 380px 380px;
  shape-outside: circle(190px);
  margin: 0 0 1em 1em;
  box-shadow: 2px 10px 30px rgba(0, 0, 0, 0.4);
  position: relative;
  touch-action: none;
  overflow: visible;
}

@media screen and (max-width: 2640px) {
  .roulette-wheel {
    float: none;
    margin: 1em auto;
  }
}

@media screen and (max-width: 375px) {
  body {
    padding: 0 20px;
  }
  .roulette-wheel {
    float: none;
    left: 30px;
  }
}

.roulette-wheel .layer-2,
.roulette-wheel .layer-3,
.roulette-wheel .layer-4,
.roulette-wheel .layer-5,
.ball-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 380px 380px;
}

.roulette-wheel .layer-2,
.roulette-wheel .layer-4,
.ball-container {
  will-change: transform;
}

.roulette-wheel .layer-2 {
  background-image: url(assets/roulette_2.png);
}

.roulette-wheel .layer-3 {
  background-image: url(assets/roulette_3.png);
}

.roulette-wheel .layer-4 {
  background-image: url(assets/roulette_4.png);
}

.roulette-wheel .layer-5 {
  background-image: url(assets/roulette_5.png);
}

.roulette-wheel svg {
  position: absolute;
  top: 0;
  left: 0;
}

.roulette-wheel circle {
  cursor: pointer;
  fill: transparent;
}

.roulette-wheel .ball {
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background: #fff radial-gradient(circle at 5px 5px, #fff, #444);
  box-shadow: 1px 1px 4px #000;
  transform: translateY(-116px);
  top: 50%;
  left: 50%;
  margin: -7px;
  will-change: transform;
}


.roulette-board {
  float: none;
  display: flow-root;
  width: 1000px;
  height: 420px;
  background: url(assets/Board.png);
  background-size: 1000px auto;
  margin: 0 auto;
}
.roulette-board-grid-numbers {
  display: grid;
  width: auto;
  height: 216px;
  margin: 27px 23px 17px 37px;
}
.roulette-board-grid-other {
  display: grid;
  width: auto;
  height: 216px;
  margin: 27px 23px 17px 37px;
}
#app table,
#app td,
#app th {
  /*border: 4px solid #fff700;
  border-collapse: collapse;*/
}



 ul {
  margin: 0;
  padding: 0;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  list-style-type: none;
  transform: translate(-50%, -50%);
}

 ul li.board-chip {
  list-style: none;
  margin: 0 15px;
  display: block;
}

ul li.board-chip div {
  position: relative; 
  display: block;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 63px;
  background: #333;
  border-radius: 50%;
  font-size: 18px;
  color: #ffff;
  transition: .5s;
  
}

ul li div::before {
  content: '';
}

ul li.board-chip div::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #ffee10;
  transition: .5s;
  transform: scale(.9);
  z-index: -1;
}

 ul li.board-chip div:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px #ffee10;
}

ul li.board-chip div:hover {
  color: #ffee10;
  box-shadow: 0 0 5px #ffee10;
  text-shadow: 0 0 5px #ffee10;
}
 ul li.board-chip div.chip_selected {
  color: #ffee10;
  box-shadow: 0 0 20px #ffee10;
  text-shadow: 0 0 5px #ffee10;
}
ul li.board-chip div.chip-100 {
  background:url('assets/chip_black.png') no-repeat;
  background-size: 60px 60px;
}
ul li.board-chip div.chip-50 {
  background:url('assets/chip_green.png') no-repeat;
  background-size: 60px 60px;
}
ul li.board-chip div.chip-20 {
  background:url('assets/chip_blue.png') no-repeat;
  background-size: 60px 60px;
}
ul li.board-chip div.chip-10 {
  background:url('assets/chip_orange.png') no-repeat;
  background-size: 60px 60px;
}
ul li.board-chip div.chip-5 {
  background:url('assets/chip_purple.png') no-repeat;
  background-size: 60px 60px;
}

div.chip-100-placed {
  background:url('assets/chip_black.png') no-repeat;
  background-size: 30px 30px;
}
div.chip-50-placed {
  background:url('assets/chip_green.png') no-repeat;
  background-size: 30px 30px;
}
div.chip-20-placed {
  background:url('assets/chip_blue.png') no-repeat;
  background-size: 30px 30px;
}
div.chip-10-placed {
  background:url('assets/chip_orange.png') no-repeat;
  background-size: 30px 30px;
}
div.chip-5-placed {
  background:url('assets/chip_purple.png') no-repeat;
  background-size: 30px 30px;
}
.roulette-actions{
  display: block;
  position: relative;
  height: 150px;
}
.chipValue {
  position: relative;
}

.chipValueImage { 
  position: absolute;
  width:30px;
  height:30px;
}
.chipSum {
  position:absolute;
  font-weight: 900;
  font-size:20px;
  -webkit-text-stroke: 1px blue;
  -webkit-text-fill-color: white;
  z-index:10;
}
.auth-user { 
  width: 250px;
  margin: 300px auto;
}
.progressBar { 
  width:400px;
  margin: 0 auto;
}
.linearProgressRounds { 
  width:400px;
  height:40px;
}
.progressRoundTitle {
  text-align:center;
  margin:0 auto;
  margin-top:20px;
  font-size: 24px;
    font-weight: 900;
    color: #fff;
}
.winnerItem { 
  font-size: 24px;
  font-weight: 600;
  margin-top:20px;
  color: #f5f5dc;
}
.winnerItemHeader { 
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  margin-top:20px;
  color: #f5f5dc;
}
.rouletteWheelWrapper {
  margin: 0 auto;
}
.winnerHistory { 
  vertical-align:top;
  width: 400px;
}
.winnersBoard { 
  vertical-align:top;
  width: 400px;
}

.walletInfo {
  vertical-align:top;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  margin-top:20px;
  color: #f5f5dc;
}

.winnerHistory .red {
  text-align: center;
  background-color: rgb(177, 6, 6);
}
.winnerHistory .green {
  text-align: center;
  background-color: rgb(42, 174, 42);
}
.winnerHistory .black {
  text-align: center;
  background-color: rgb(41, 40, 40);
}
.winnerHistory {

}
.winnerHistory div {
  float:left; 
  color:#fff;
  font-weight: 900;
  line-height: 40px;;
  margin-left:20px;
  margin-top:10px;
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.hideElementsTest {
 /* visibility: hidden;*/
}

.modal {
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: rgba(0, 160, 246, 0.76);
  border: 1px solid #000000;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.1);
}

.game-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
}

.game-modal {
  position: relative;
  width: 80%;
  max-width: 400px;
  padding: 30px;
  background-color: #1a531b;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  outline: none;
  color: whitesmoke;
}

.terms-modal {
  position: relative;
  width: 80%;
  max-width: 400px;
  padding: 30px;
  background-color: #1a531b;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  outline: none;
  max-height: 500px;
  overflow-y: scroll;
}

.modal h2 {
  font-size: 24px;
  color: #333;
}

.modal p {
  font-size: 16px;
  color: #666;
  margin-top: 10px;
}

.modal .close {
  margin-top: 20px;
  background-color: #4285F4;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
}

.modal .close:hover {
  background-color: #2a56c6;
}

.button-style {
  margin-left: 2em;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-radius: 5px;
  margin-top: 1em;
  font-size: 16px;
  margin-bottom: 1em;
  transition: background-color 0.3s ease;
}

.button-style:hover {
  background-color: rgba(0, 0, 0, 0.1); /* replace with any color hue you want */
}