.game-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
}

.modal-close-button {
    position: relative;
    z-index: 10;
}

.game-modal {
    position: relative;
    width: 80%;
    max-width: 400px;
    padding: 30px;
    background-color: #1a531b;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    outline: none;
}

.game-modal h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #ffffff;
    text-align: center;
}

.game-modal button {
    display: block;
    margin: 0 auto;
    padding: 10px 20px;
    background-color: #3a793a;
    border: none;
    border-radius: 5px;
    color: #ffffff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.game-modal button:hover {
    background-color: #4ca24c;
}